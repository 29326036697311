import { api, API_PATHS } from './api'
import { ExternalLoginRequest, LoginResponse, LoginRequest, RegisterRequest, RegisterResponse, WizardPatchParams } from '../../types'

export const login = async (request: LoginRequest): Promise<LoginResponse | undefined> => {
  const res = await api.post<LoginResponse>(API_PATHS.auth.login, { ...request })
  return res.data
}

export const externalLogin = async (request: ExternalLoginRequest): Promise<LoginResponse | undefined> => {
  const res = await api.post<LoginResponse>(API_PATHS.auth.externalLogin, { ...request })
  return res.data
}

export const register = async (request: RegisterRequest): Promise<RegisterResponse> => {
  const res = await api.post<any>(API_PATHS.auth.register, { ...request })
  if (res.status === 200 || res.status === 201) return { success: true }
  if (res.status === 409) {
    return {
      success: false,
      message: 'userExists'
    }
  }
  return { success: false, message: 'error' }
}

export const logout = async (): Promise<boolean> => {
  const res = await api.get(API_PATHS.auth.logout)
  return res.status === 200 || res.status === 201
}

export const finishWizard = async (params: WizardPatchParams) => {
  const res = await api.patch(API_PATHS.user.me, params)
  return res.status === 200 || res.status === 201
}

export const updateUserName = async (params: { firstName: string; lastName: string }) => {
  const res = await api.patch(API_PATHS.user.me, params)
  return res.status === 200 || res.status === 201
}

export const requestPasswordReset = async (email: string) => {
  const res = await api.post(API_PATHS.user.forgotPassword, { email })
  return res.status === 200 || res.status === 201
}

export const updatePassword = async (token: string, newPassword: string) => {
  const res = await api.post(API_PATHS.user.updatePassword, { token, newPassword })
  console.log(res)
  return res.status === 200 || res.status === 201
}

export const deleteAccount = async () => {
  const res = await api.delete(API_PATHS.user.me)
  return res.status === 200 || res.status === 201
}

export const verifyEmail = async (token: string) => {
  const res = await api.post(API_PATHS.auth.verifyEmail, { token })
  return res.status === 200 || res.status === 201
}
