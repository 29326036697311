
import { flow, types } from "mobx-state-tree";
import { getMenu } from "../services/api";

export const APIResultModel = types.model({
  id: types.number,
  name: types.string,
  URL: types.string
})

export const MenuStore = types
  .model({
    menuItems: types.optional(types.array(APIResultModel), []),
  })
  .actions(self => {

    const fetchMenu = flow(function* () {
      const results = yield getMenu();
      console.log(results);
      self.menuItems = results.data;
      return results.data;
    })

    return {
      fetchMenu
    }
  });
