import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import moment from 'moment'

import { Button, Container, Input, Select, SelectOption, Text, Title } from './shared'
import { useMst } from '../../models/Root'
import { isValidName } from '../../utils/inputValidation'
import { InputError } from '../../types'
import styled from 'styled-components'
import { colors, fonts } from '../../styles/variables'
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons'

const NewsletterContainer = styled('div')({
  display: 'flex',
  alignItems: 'center'
})

const FormControl = styled(FormControlLabel)({
  marginRight: '10px',
  '& .MuiTypography-body1': {
    fontFamily: fonts.ptMono
  }
})

const CheckBoxBlank = styled(CheckBoxOutlineBlank)({
  color: colors.white
})

const CheckboxPressed = styled(CheckBox)({
  color: colors.white
})

type Props = {}

export const Wizard: FC<Props> = observer(() => {
  const {
    userStore: { user, finishWizard, fetching }
  } = useMst()
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [gender, setGender] = useState<string>('')
  const [birthYear, setBirthYear] = useState<string>('')
  const [subscribeToNewsLetter, setSubscribeToNewsLetter] = useState<boolean>(false)
  const [errors, setErrors] = useState<InputError[]>([])

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName?.trim() ?? '')
      setLastName(user.lastName?.trim() ?? '')
    }
  }, [user])

  const validateInputs = () => {
    let validationErrors: InputError[] = []

    setFirstName(firstName.trim())
    setLastName(lastName.trim())

    if (firstName.length && !isValidName(firstName.trim())) {
      validationErrors.push({ input: 'firstName', message: 'invalidFirstName' })
    }
    if (lastName.length && !isValidName(lastName.trim())) {
      validationErrors.push({ input: 'lastName', message: 'invalidLastName' })
    }

    setErrors(validationErrors)
    return validationErrors.length
  }

  const genderOptions: SelectOption[] = [
    { label: 'Mies', value: 'male' },
    { label: 'Nainen', value: 'female' },
    { label: 'En halua sanoa', value: 'dns' }
  ]

  // Returns options from current year to -100 years
  const getYearOptions = (): SelectOption[] => {
    const currentYear = moment().local().year()
    const options: SelectOption[] = []
    for (let i = currentYear; i > currentYear - 100; i--) {
      options.push({ label: i.toString(), value: i.toString() })
    }
    return options
  }

  const disabled = !firstName.length || !lastName.length || fetching || !!errors.length

  const toggleNewsletterSub = () => setSubscribeToNewsLetter(!subscribeToNewsLetter)

  const handleSend = () =>
    finishWizard({
      firstName,
      lastName,
      subscribeToNewsLetter,
      gender: gender.length ? gender : undefined,
      birthYear: birthYear.length ? Number(birthYear) : undefined
    })

  return (
    <Container>
      <Title>Täytä vielä henkilötietosi</Title>
      <Text>
        Rekisteröityminen kannattaa! Kun kirjaudut Dei Plussaan, näet ohjelmasuosikkisi eri laitteilla ja voit helposti jatkaa kesken
        jäänyttä ohjelmaa. Tunnuksena toimii sähköpostiosoitteesi.
      </Text>
      <Input
        type="text"
        label="Etunimi"
        value={firstName}
        onChange={setFirstName}
        onBlur={validateInputs}
        errorMessage={errors.find((err) => err.input === 'firstName')?.message}
        required
      />
      <Input
        type="text"
        label="Sukunimi"
        value={lastName}
        onChange={setLastName}
        onBlur={validateInputs}
        errorMessage={errors.find((err) => err.input === 'lastName')?.message}
        required
      />
      <Select label="Sukupuoli" value={gender} onChange={setGender} options={genderOptions} />
      <Select label="Syntymävuosi" value={birthYear} onChange={setBirthYear} options={getYearOptions()} />
      <NewsletterContainer>
        <FormControl
          control={
            <Checkbox
              name="newsletter"
              icon={<CheckBoxBlank color="inherit" />}
              checkedIcon={<CheckboxPressed color="inherit" />}
              checked={subscribeToNewsLetter}
              onChange={toggleNewsletterSub}
            />
          }
          label="Haluan tilata Dei Plus -uutiskirjeen"
        />
      </NewsletterContainer>
      <Button fetching={fetching} onClick={handleSend} disabled={disabled}>
        Jatka Dei Plussaan
      </Button>
    </Container>
  )
})
