import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'



interface Props {
}

const MobileSmartBanner: React.FC<Props> = observer((props) => {

  const BannerContainer = styled.div`
    color: white;
    font-family: 'Source Code Pro',monospace;
    padding: 25px;
    font-size: 75%;
    font-weight: normal;
  `;

  const CloseBannerButton = styled.div`
    float:right;
    font-weight: bold;
    font-size: 150%;
    padding-left: 10px;
    padding-right: 10px;
  `

  const BannerHeader = styled.span`
    font-size:120%;
    font-weight: bold;
  `;

  const BannerAppstoreButton = styled.a`
    background-color: #b4002b;
    font-weight: bold;
    font-size: 120%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    width: fit-content;
    color: white;
  `

  const androidLink = "https://play.google.com/store/apps/details?id=fi.dei.radiodeiplus";
  const iOSLink = "https://apps.apple.com/fi/app/dei-plus/id1596786100?l=fi";

  const [hidden, setHidden] = useState(false);

  const getOS = () => {
    // @ts-ignore
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  console.log('Detected OS: ', getOS());

  if (getOS() !== 'Android' && getOS() !== 'iOS') {
    return <div />;
  }

  return (
    <BannerContainer style={{ display: hidden ? 'none' : '' }}>
      <BannerHeader>Dei+ on nyt myös sovellus!</BannerHeader>
      <CloseBannerButton onClick={() => setHidden(true)}>X</CloseBannerButton>
      <br /><br />

      Ilmainen Dei+ sovellus on nyt saatavissa sovelluskaupasta Android ja iOS -laitteille.

      <br />
      <br />

      <BannerAppstoreButton href={getOS() === 'iOS' ? iOSLink : androidLink}>Lataa sovellus</BannerAppstoreButton>
    </BannerContainer>);


})

export { MobileSmartBanner }
