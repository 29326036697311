import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { List, ListItem } from '@material-ui/core'
import { CallMade } from '@material-ui/icons'

import { MenuToggle } from './MenuToggle'
import { colors } from '../../styles/variables'
import { SearchBar } from '../SearchBar'
import { LoginButton } from '../Auth'

import './Navi.css'

const MenuContainer = styled.div`
  background: ${colors.black};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
`

const MenuToggleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  height: 80px;
`

const MobileMenuText = styled.div`
  color: ${colors.white};
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-right: -15px;
`

const MobileLinkContainer = styled(List)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2rem 0;
`

const MobileMenuItemLink = styled(Link)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.white};
  text-decoration-line: underline;
  text-align: center;
  margin-right: 6px;
`

const ExternalMenuItemLink = styled.a`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin: 1em;
  display: flex;
  align-items: center;
  color: ${colors.white};
  text-decoration-line: underline;
`

const autoWidthStyle = {
  width: 'auto'
}

interface MobileMenuProps {
  menuOpen: boolean
  onMenuToggleClick: () => void
  menuItems: Array<{
    URL: string
    id: number
    name: string
    placement: number
  }>
}

const Menu: React.FC<MobileMenuProps> = ({ menuOpen, onMenuToggleClick, menuItems }) => {
  console.log('OUR ITEMS', menuItems)
  const menuElements = (menuItems ?? []).map((mi) => {
    const isInsideLink = mi.URL.charAt(0) === '/' || mi.URL.includes('deiplus')
    return (
      <ListItem key={mi.id} style={autoWidthStyle} alignItems="center" autoFocus button onClick={onMenuToggleClick}>
        {isInsideLink ? (
          <MobileMenuItemLink to={mi.URL}>{mi.name}</MobileMenuItemLink>
        ) : (
          <>
            <ExternalMenuItemLink href={mi.URL} target="_blank">
              {mi.name}
            </ExternalMenuItemLink>
            <CallMade />
          </>
        )}
      </ListItem>
    )
  })

  return (
    <MenuContainer className={'Menu-container'}>
      <MenuToggleContainer className={'Menu-toggle-container'}>
        <MobileMenuText>Sulje</MobileMenuText>
        <MenuToggle onClick={onMenuToggleClick} isOpen={menuOpen} />
      </MenuToggleContainer>
      <SearchBar closeMenu={onMenuToggleClick} />
      <MobileLinkContainer className={'Menu-link-container'}>
        <LoginButton />
        {menuElements}
      </MobileLinkContainer>
    </MenuContainer>
  )
}

export { Menu }
