import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import 'modern-normalize'
import '../styles/normalize'

import Header from '../components/Header'
import Footer from '../components/Footer'
import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'
import { MediaPlayer } from '../components/MediaPlayer'
import { rootStore, StoreProvider } from '../models/Root'
import useIsClient from '../components/RehydrationFixer/RehydrationFixer'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const IndexLayout: React.FC = ({ children }) => {
  const { isClient, key } = useIsClient()

  if (!isClient) return null

  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={(data: StaticQueryProps) => (
        <div style={{ marginBottom: '150px' }} key={key}>
          <StoreProvider value={rootStore}>
            <LayoutRoot>
              <Helmet
                title={data.site.siteMetadata.title}
                meta={[
                  { name: 'description', content: data.site.siteMetadata.description },
                  { name: 'keywords', content: data.site.siteMetadata.keywords }
                ]}
              />
              <Header title={data.site.siteMetadata.title} />
              <LayoutMain>{children}</LayoutMain>
              <Footer />
            </LayoutRoot>
            <MediaPlayer />
          </StoreProvider>
        </div>
      )}
    />
  )
}

export default IndexLayout
