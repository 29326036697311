import React, { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Button, CenteredContainer, ClearButton, Container, Input, Text, Title } from './shared'
import { useMst } from '../../models/Root'
import { AuthModalPhase } from '../../models/AuthStore'
import { InputError } from '../../types'
import { isEmail } from '../../utils/inputValidation'

type Props = {}

export const ForgotPassword: FC<Props> = observer(() => {
  const {
    authStore: { setPhase, requestPasswordReset, fetching }
  } = useMst()
  const [email, setEmail] = useState<string>('')
  const [errors, setErrors] = useState<InputError[]>([])
  const [success, setSuccess] = useState<boolean>(false)

  const validateInputs = () => {
    setEmail(email.trim())

    let validationErrors: InputError[] = []

    if (email.trim().length && !isEmail(email.trim())) {
      validationErrors.push({ input: 'email', message: 'Tarkista sähköpostiosoite' })
    }

    setErrors(validationErrors)
    return validationErrors.length
  }

  const handleSendClick = async () => {
    const hasErrors = validateInputs()
    if (hasErrors) return
    setSuccess(await requestPasswordReset(email))
  }
  const handleLoginClick = () => setPhase(AuthModalPhase.Login)

  const disabled = !email.trim().length || !!errors.length || fetching

  return (
    <Container>
      <CenteredContainer>
        <Title>Unohtuiko salasanasi?</Title>
      </CenteredContainer>
      <Text>
        Ei hätää! Anna sähköpostiosoitteesi, jolla olet rekisteröitynyt palveluumme, niin lähetämme sinulle linkin, jonka kautta voit
        vaihtaa salasanasi.
      </Text>
      <Input
        type="email"
        label="Sähköposti"
        value={email}
        onChange={setEmail}
        onBlur={validateInputs}
        errorMessage={errors.find((err) => err.input === 'email')?.message}
      />
      <Button disabled={disabled} fetching={fetching} onClick={handleSendClick}>
        Lähetä
      </Button>
      {success && <Text>Sähköposti lähetetty! Muista tarkistaa roskapostikansiosi.</Text>}
      <CenteredContainer>
        <ClearButton onClick={handleLoginClick}>Palaa kirjautumiseen</ClearButton>
      </CenteredContainer>
    </Container>
  )
})
