import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'

import { CenteredContainer, ClearButton, Container, Text, Title } from './shared'
import { useMst } from '../../models/Root'
import { AuthModalPhase } from '../../models/AuthStore'

type Props = {}

export const VerifyEmail: FC<Props> = observer(() => {
  const {
    authStore: { setPhase }
  } = useMst()
  const handleLoginClick = () => setPhase(AuthModalPhase.Login)

  return (
    <Container>
      <Title>Vahvista sähköpostiosoitteesi</Title>
      <Text>
        {`Lähetimme sinulle sähköpostia.\n\nKlikkaa sähköpostista löytyvää linkkiä, jonka kautta voit vahvistaa sähköpostiosoitteesi. Jos et saanut sähköpostia, tarkista roskapostikansiosi ja osoite, jolla rekisteröidyit.`}
      </Text>
      <CenteredContainer>
        <ClearButton onClick={handleLoginClick}>Takaisin kirjautumiseen</ClearButton>
      </CenteredContainer>
    </Container>
  )
})
