import React, { useState, MouseEvent, useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { useMst } from '../../models/Root'
import { useMediaQuery } from 'react-responsive'

import SomeButtons from '../SomeButtons/SomeButtons'
import { MediaFile } from '../../types'
import { breakpoints, colors } from '../../styles/variables'
import { DesktopContent } from './DesktopContent'
import { MobileContent } from './MobileContent'
//@ts-ignore
import Chevron from '../../resources/icons/additional-info.svg'

const AdditionalInfoButton = styled.button`
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  background-color: transparent;
  border: none;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
`

const Icon = styled(Chevron)`
  transition: 250ms all;
  width: 25px;
  height: 20px;
  margin-bottom: 6px;
`

const AdditionaInfoText = styled.span`
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  transition: all 250ms;
  color: ${colors.white};
`

const AdditionalInfoContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 250ms all;
  width: 100vw;
  background-color: #000;
  z-index: 1;
  display: flex;
  justify-content: center;
`

export const AdditionalMediaInfo: FC = observer(() => {
  const {
    mediaPlayer: { mediaId, isPlaying, isLive, getMediaFile, mediaSeriesId }
  } = useMst()
  const isMobile = useMediaQuery({ maxWidth: breakpoints.sm })
  const [additionalInfoOpen, setAdditionalInfoOpen] = useState<boolean>(false)
  const [additionalInfo, setAdditionalInfo] = useState<MediaFile | null>(null)
  const [fetching, setFetching] = useState<boolean>(false)

  useEffect(() => {
    const handleGet = async () => {
      setFetching(true)
      const res = await getMediaFile(String(mediaId), String(mediaSeriesId))
      if (res) setAdditionalInfo(res)
      setFetching(false)
    }
    if (typeof mediaId === 'number' && additionalInfo?.xmlId !== mediaId) {
      console.log(mediaId)
      handleGet()
    }
  }, [mediaId])

  const handleAdditionalInfoPress = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setAdditionalInfoOpen(!additionalInfoOpen)
  }

  const disabled = !isPlaying && !isLive

  console.log(isPlaying)
  console.log(isLive)
  console.log(mediaId)

  return (
    <>
      <AdditionalInfoButton onClick={handleAdditionalInfoPress} style={{ opacity: disabled ? 0 : 1 }} disabled={disabled}>
        <Icon style={{ transform: `rotate(${additionalInfoOpen ? 180 : 0}deg)` }} />
        <AdditionaInfoText>{additionalInfoOpen ? 'sulje' : 'lisätiedot'}</AdditionaInfoText>
      </AdditionalInfoButton>
      <AdditionalInfoContainer
        style={{
          height: additionalInfoOpen ? (isMobile ? '100%' : 500) : 0,
          paddingTop: additionalInfoOpen ? 50 : 0,
          paddingBottom: additionalInfoOpen ? 100 : 0
        }}
      >
        {isMobile ? (
          <MobileContent
            fetching={fetching}
            mediaId={mediaId}
            visible={additionalInfoOpen}
            onAdditionalInfoClose={() => setAdditionalInfoOpen(false)}
            additionalInfo={additionalInfo}
            isLive={isLive}
          />
        ) : (
            <DesktopContent
              fetching={fetching}
              mediaId={mediaId}
              visible={additionalInfoOpen}
              onAdditionalInfoClose={() => setAdditionalInfoOpen(false)}
              additionalInfo={additionalInfo}
              isLive={isLive}
            />
          )}

        {/* <SomeButtons /> */}
      </AdditionalInfoContainer>
    </>
  )
})
