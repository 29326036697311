import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Modal as MUIModal } from '@material-ui/core'

import { breakpoints, colors } from '../../styles/variables'
import { useMst } from '../../models/Root'
import { AuthModalPhase } from '../../models/AuthStore'
import { Login } from './Login'
import { Register } from './Register'
import { ForgotPassword } from './ForgotPassword'
import { VerifyEmail } from './VerifyEmail'
import { Wizard } from './Wizard'

const Modal = styled(MUIModal)`
  display: flex;
  flex: 1 1 0;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.black,
  padding: '4rem 5rem',
  position: 'relative',
  overflowY: 'scroll',
  [`@media ${`(min-width: ${breakpoints.sm}px)`}`]: {
    width: breakpoints.sm
  },
  [`@media ${`(max-width: ${breakpoints.sm}px)`}`]: {
    width: '100%'
  }
})

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.black};
  border: none;
  color: ${colors.white};
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
`

const X = styled.span`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 22px;
  margin-right: 10px;
`

type Props = {
  onOpen: () => void
}

export const AuthModal: FC<Props> = observer(({ onOpen }) => {
  const {
    authStore: { setPhase, modalPhase, isModalOpen, preventModalClose }
  } = useMst()

  useEffect(() => {
    if (modalPhase !== AuthModalPhase.None) onOpen()
  }, [modalPhase, onOpen])

  const handleCloseModal = () => (preventModalClose ? undefined : setPhase(AuthModalPhase.None))

  const renderContent = () => {
    switch (modalPhase) {
      case AuthModalPhase.Login:
        return <Login />
      case AuthModalPhase.Register:
        return <Register />
      case AuthModalPhase.ForgotPassword:
        return <ForgotPassword />
      case AuthModalPhase.VerifyEmail:
        return <VerifyEmail />
      case AuthModalPhase.Wizard:
        return <Wizard />
      case AuthModalPhase.None:
      default:
        return null
    }
  }

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <ContentContainer>
        {!preventModalClose && (
          <CloseButton onClick={handleCloseModal}>
            <X>X</X>
            <span>sulje</span>
          </CloseButton>
        )}
        {renderContent()}
      </ContentContainer>
    </Modal>
  )
})
