import * as React from 'react'
import styled from '@emotion/styled'

const StyledContainer = styled.div`
  position: relative;
  // margin-left: 130px;
  width: 100%;
  // margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  overflow: hidden;
`

interface ContainerProps {
  className?: string
}

const Container: React.FC<ContainerProps> = ({ children, className }) => <StyledContainer className={className}>{children}</StyledContainer>

export default Container
