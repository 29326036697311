import { cast, flow, types } from 'mobx-state-tree'
import { addToFavorites, deleteUserFavorite, getMediaData, getUserFavorites } from '../services/api'
import STORAGE, { StorageKey } from '../services/storage'
import { FlowType, Favorite, LocalFavorite } from '../types'
import { MediaSeriesModel, MediaSeriesType } from './Search'
import { withRootStore } from './withRootStore'

const LocalFavoriteModel = types.model('LocalFavoriteModel', {
  id: types.number,
  series: types.boolean
})

export const FavoritesStore = types
  .model({
    state: types.enumeration(['Initial', 'Loading', 'Error', 'Done']),
    localFavorites: types.array(LocalFavoriteModel),
    favorites: types.array(MediaSeriesModel),
    needToFetch: types.boolean
  })
  .extend(withRootStore)
  .views((self) => ({
    checkIfIdFavorited(id: number): boolean {
      return !!self.localFavorites?.find((f) => f.id === id)
    }
  }))
  .actions((self) => {
    const fetchLocalFavorites = flow(function* (): FlowType<LocalFavorite[] | undefined> {
      let localFavorites: LocalFavorite[] = []
      if (self.rootStore.userStore.user) {
        localFavorites = (yield getUserFavorites(self.rootStore.userStore.user.id)).map((f: Favorite) => ({
          id: f.mediaId,
          series: f.isSeries
        }))
      } else {
        localFavorites =
          STORAGE.read({ key: StorageKey.favorites })?.map((f: { id: string; series: boolean }) => ({
            id: Number(f.id),
            series: f.series
          })) ?? []
      }
      return localFavorites
    })

    const initializeFavorites = flow(function* (): FlowType<void> {
      const localFavorites: LocalFavorite[] | undefined = yield fetchLocalFavorites()
      self.localFavorites = cast(localFavorites ?? [])
      if (localFavorites?.length) {
        const ids = new Set()
        const filteredFavorites = localFavorites.filter(
          obj => !ids.has(obj.id) && ids.add(obj.id)
        )
        const res: MediaSeriesType[] = yield getMediaData(filteredFavorites)
        self.favorites = cast(res)
      }
      self.state = 'Done'
    })

    const favoriteById = flow(function* (id: number, series: boolean): FlowType<void> {
      const existingFavorite = self.favorites.find((f) => f.id === id)
      if (existingFavorite) return

      const newFavorites = [...self.localFavorites, { id, series }]
      self.localFavorites = cast(newFavorites)

      // Add to "local" favorites
      if (self.rootStore.userStore.user) {
        yield addToFavorites(self.rootStore.userStore.user.id, id, series)
      } else {
        STORAGE.write({ key: StorageKey.favorites, value: newFavorites })
      }

      const ids = new Set()
      const filteredFavorites = newFavorites.filter(
        obj => !ids.has(obj.id) && ids.add(obj.id)
      )
      // Get favorite info from api and save to state
      const res: MediaSeriesType[] = yield getMediaData(filteredFavorites)
      self.favorites = cast(res)
    })

    const unFavoriteById = flow(function* (id: number): FlowType<void> {
      const existingFavorite = self.favorites.find((f) => f.id === id)
      if (!existingFavorite) return

      const newFavorites = [...self.localFavorites].filter((f) => f.id !== id)
      self.localFavorites = cast(newFavorites)

      // Remove from "local" favorites
      if (self.rootStore.userStore.user) {
        yield deleteUserFavorite(self.rootStore.userStore.user.id, id, existingFavorite.seriesLink)
      } else {
        STORAGE.write({ key: StorageKey.favorites, value: newFavorites })
      }

      const ids = new Set()
      const filteredFavorites = newFavorites.filter(
        obj => !ids.has(obj.id) && ids.add(obj.id)
      )

      // Get favorite info from api and save to state
      const res: MediaSeriesType[] = yield getMediaData(filteredFavorites)
      self.favorites = cast(res)
    })

    return {
      favoriteById,
      unFavoriteById,
      initializeFavorites
    }
  })
