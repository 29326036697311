import React, { ChangeEvent, FC, InputHTMLAttributes, useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { InputError } from '../types'
import { isEmail } from '../utils/inputValidation'
import {
  subscribeNewsLetter
} from '../services/api'
import { breakpoints, colors, fonts } from '../styles/variables'

const NewsLetterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
  background-color: ${colors.black};
  padding: 1.5em 10% 4% 10%;
  width: 100%;

  @media ${`(max-width: 900px)`} {
    padding-top: 0;
  }
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props: FooterLinkContainerProps) => (props.isMobile ? 'column' : 'row')};
  @media ${`(max-width: 900px)`} {
    padding-top: 0;
  }
`

const NewsLetterTextContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: ${(props: FooterLinkContainerProps) => (props.isMobile ? '100%' : '50%;')};

  @media ${`(max-width: 900px)`} {
    padding-top: 0;
  }
`
const SubscripbeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  width: ${(props: FooterLinkContainerProps) => (props.isMobile ? '100%' : '45%;')};

  @media ${`(max-width: 900px)`} {
    padding-top: 0;
  }
`

const NewsLetterHeader = styled.div`
  font-family: ${fonts.montserrat};
  font-size: 32px;
  line-height: 39px;
  color: ${colors.white};
  margin-bottom: 2em;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    font-size: 18px;
    line-height: 22px;
  }
`

const NewsLetterText = styled.div`
  font-family: ${fonts.codeProMono};
  font-size: 32px;
  line-height: 39px;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    font-size: 11px;
    line-height: 14px;
  }
`

const NewsLetterTextBolded = styled.div`
  font-family: ${fonts.montserrat};
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
  }
`

const Separator = styled.div`
  background-color: ${colors.white};
  width: 100%;
  height: 1px;
  margin: 20px 0;
`

const Button = styled.button.attrs((props: { disabled: boolean }) => props)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 70%;
  align-self: flex-start;
  max-height: 400px;
  border: none;
  background: none;
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
  background-color: ${colors.deiRed};
  padding: 15px 30px;
  align-items: center;
  color: ${colors.white};
  font-weight: bold;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    width: 100%;
  }
`
// Email input
const InputLabel = styled.label`
  font-family: ${fonts.montserrat};
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  margin-bottom: 5px;
`

const InputComponent = styled.input.attrs((props: { error: boolean }) => props)`
  font-family: ${fonts.montserrat};
  font-size: 14px;
  line-height: 18px;
  background-color: ${colors.white};
  padding: 10px 22px;
  border: ${(props) => props.error ? `2px solid ${colors.warning}` : `2px solid ${colors.white}`};
  width: 100%;
`

const MessageContainer = styled.div`
  width: 100%;
  height: 32px;
`

const ErrorMessage = styled.p`
  font-family: ${fonts.codeProMono};
  font-size: 16px;
  line-height: 18px;
  color: ${colors.warning};
  width: 100%;
  padding-top: 5px;
`
const SuccessMessage = styled.p`
  font-family: ${fonts.codeProMono};
  font-size: 16px;
  line-height: 18px;
  color: ${colors.white};
  width: 100%;
  padding-top: 5px;
`
const InputContainer = styled.div`
  width: 100%;
`

type InputProps = {
  type: InputHTMLAttributes<HTMLInputElement>['type']
  label: string
  value: string
  onChange: (e: string) => void
  errorMessage?: string
  required?: boolean
  successMessage?: string
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>

const Input: FC<InputProps> = ({ type, label, value, onChange, errorMessage, successMessage, ...rest }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)

  return (
    <InputContainer>
      <>
        <InputLabel>{label}</InputLabel>
      </>
      <InputComponent {...rest} type={type} value={value} onChange={handleChange} error={!!errorMessage?.length} />
      <MessageContainer>
        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {!!successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      </MessageContainer>
    </InputContainer>
  )
}

interface NewsLetterProps { }

interface FooterLinkContainerProps {
  isMobile: Boolean
}

const NewsLetter: React.FC<NewsLetterProps> = () => {
  const [email, setEmail] = useState<string>('')
  const [errors, setErrors] = useState<InputError[]>([])
  const [success, setSuccess] = useState<string>('')
  const isMobile = useMediaQuery({ maxWidth: 900 })

  const validateInputs = () => {
    setEmail(email.trim())

    let validationErrors: InputError[] = []

    if (email.trim().length && !isEmail(email.trim())) {
      validationErrors.push({ input: 'email', message: 'Tarkista sähköpostiosoite' })
    }

    setErrors(validationErrors)
    return validationErrors.length
  }

  const subscribe = async () => {
    subscribeNewsLetter(email).then(response => {
      if (response.data.statusCode === 400) {
        setErrors([{ input: 'email', message: 'Olet jo uutiskirjeen tilaaja' }])
        setSuccess('')
      }
      else if (response.data.statusCode === 200) {
        setSuccess('Olet nyt uutiskirjeen tilaaja')
        setErrors([])
        setEmail('')
      } else {
        setErrors([{ input: 'email', message: 'Uutiskirjeen tilauksessa tapahtui virhe' }])
        setSuccess('')
      }

    })
  }

  const disabled = !email.trim().length || !!errors.length

  return (
    <div>
      <NewsLetterContainer>
        <NewsLetterHeader>Pysy ajan tasalla - tilaa uutiskirje!</NewsLetterHeader>
        <ContentContainer isMobile={isMobile}>
          <NewsLetterTextContainer isMobile={isMobile}>
            <NewsLetterText>
              <p>Uutiskirjeen tilaajana kuulet aina ensimmäisenä Dei Plussan uusista ominaisuusksista, herkullisimmista sisällöistä ja mauista uutisista</p>
              <p>Tilaaminen on helppoa: kirjoita sähköpostiosoitteesi ja klikkaa Tilaa uutiskirja</p>
            </NewsLetterText>
            <Separator />
            <NewsLetterTextBolded>Tervetuloa nauttimaan Dei Plussasta!</NewsLetterTextBolded>
          </NewsLetterTextContainer>
          <SubscripbeContainer isMobile={isMobile}>
            <Input
              type="email"
              label="Sähköposti"
              value={email}
              onChange={setEmail}
              onBlur={validateInputs}
              errorMessage={errors.find((err) => err.input === 'email')?.message}
              successMessage={success}
            />
            <Button disabled={disabled} onClick={subscribe}>Tilaa uutiskirje</Button>
          </SubscripbeContainer>
        </ContentContainer>
      </NewsLetterContainer>
    </div>
  )
}

export default NewsLetter
