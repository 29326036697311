import React, { useRef, useEffect, useLayoutEffect } from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
// import 'react-h5-audio-player/lib/styles.css';
import { observer } from 'mobx-react-lite'
import { useMst } from '../../models/Root'

// import './mobilemediaplayer.scss'
import '../MediaPlayer/mediaplayer.scss'
import styled from 'styled-components'
// @ts-ignore
import SkipBackwardIcon from '../../resources/icons/skip-backward.svg'
// @ts-ignore
import SkipForwardIcon from '../../resources/icons/skip-forward.svg'
import STORAGE, { StorageKey } from '../../services/storage'
import { submitPopularityPulse } from '../../services/api'
import { colors } from '../../styles/variables'
import { Pause, PlayArrow } from '@material-ui/icons'

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: space-between;
`

const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100px;
  border: 1px solid ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MobileMediaPlayer = observer(() => {
  const {
    mediaPlayer,
    progress: { updateInProgress, removeFromInProgress, updteProgressEntries }
  } = useMst()

  let playerRef = useRef<AudioPlayer>(null)

  useEffect(() => {
    console.log('player ref changed, updating')
    mediaPlayer.setPlayerRef(playerRef)
  }, [playerRef])

  useLayoutEffect(() => {
    if (playerRef.current) {
      if (mediaPlayer.isPlaying && !playerRef.current?.isPlaying()) {
        console.log('Store says playing, but player isnt playing. Lets play - NOT')

        playerRef.current?.audio.current?.load()

        // //@ts-expect-error
        // playerRef.current?.audio.current.play()
      }
    }
  }, [mediaPlayer.isPlaying])

  useLayoutEffect(() => {
    // @ts-ignore
    console.log('Media player ref changed. lets see', mediaPlayer.mediaPlayerRef?.current?.audio)
  }, [mediaPlayer.mediaPlayerRef])

  useLayoutEffect(() => {
    if (mediaPlayer.pauseRequested === true) {
      console.log('PAUSE HAS BEEN REQUESTED!!')
      // @ts-ignore
      playerRef.current.audio.current.pause()
      mediaPlayer.requestPause(false)
    }
  }, [mediaPlayer.pauseRequested])

  useLayoutEffect(() => {
    if (mediaPlayer.resumeRequested) {
      console.log('RESUME HAS BEEN REQUESTED!!')
      // @ts-ignore
      playerRef.current.audio.current.play()
      mediaPlayer.requestResume(false)
    }
  }, [mediaPlayer.resumeRequested])

  useLayoutEffect(() => {
    if (mediaPlayer.skipRequested === true) {
      // @ts-ignore
      playerRef.current.audio.current.currentTime = mediaPlayer.existingProgress
      mediaPlayer.clearProgress()
    }
  }, [mediaPlayer.skipRequested])

  const customIcons = {
    play: (
      <IconContainer>
        <PlayArrow style={{ color: colors.white }} />
      </IconContainer>
    ),
    pause: (
      <IconContainer>
        <Pause style={{ color: colors.white }} />
      </IconContainer>
    ),
    rewind: <SkipBackwardIcon />,
    forward: <SkipForwardIcon />
  }

  const handleListen = (e: Event) => {
    const position = playerRef.current?.audio.current?.currentTime
    const duration = playerRef.current?.audio.current?.duration
    if (e.target !== null && mediaPlayer.isPlaying && !mediaPlayer.isLive && !!position && !!duration) {
      console.log('AUDIO TICK')
      const progress = position / duration
      if (progress >= 0.99) {
        removeFromInProgress(mediaPlayer.mediaId)
      } else {
        updateInProgress(mediaPlayer.mediaId, position)
      }

      if (!mediaPlayer.isLive) {
        let existingPopularityTracking = STORAGE.read({ key: StorageKey.poptrack })

        if (!existingPopularityTracking) {
          existingPopularityTracking = {}
        }

        if (!existingPopularityTracking[mediaPlayer.mediaId]) {
          existingPopularityTracking[mediaPlayer.mediaId] = { t: 1, s: false, tr: Math.floor(mediaPlayer.mediaDuration * 0.1) }
        } else {
          let temp = existingPopularityTracking[mediaPlayer.mediaId]
          temp.t += 1
          if (temp.t >= temp.tr && !temp.s) {
            console.log('WE MUST SEND POPULARITY PULSE TO BACKEND!')
            submitPopularityPulse(mediaPlayer.mediaId)
            temp.s = true
          }
          existingPopularityTracking[mediaPlayer.mediaId] = temp
        }

        STORAGE.write({ key: StorageKey.poptrack, value: existingPopularityTracking })
      }
    }
  }

  const handlePause = (e: Event) => {
    if (e.target && mediaPlayer.isPlaying) {
      mediaPlayer.setPause(true)
      updteProgressEntries()
    }
  }

  const handlePlay = (e: Event) => {
    if (e.target) {
      mediaPlayer.setPause(false)
      mediaPlayer.setIsPlaying(true)
    }
  }

  const handleCanPlayThrough = () => {
    console.log('CAN PLAY THROUGH? START?')

    // try {
    //   mediaPlayer.playThrough();
    // @ts-ignore
    if (playerRef) {
      if (playerRef.current) {
        // @ts-ignore
        if (playerRef.current.audio) {
          // @ts-ignore
          if (playerRef.current.audio.current) {
            console.log('Canplaythrough is ready. can we play?')

            if (mediaPlayer.isPlaying) {
              // @ts-ignore
              playerRef?.current?.audio?.current
                ?.play()
                .then(() => {
                  // @ts-ignore
                  playerRef?.current?.audio?.current?.play()
                })
                .catch(() => {
                  const audioElement = document.querySelector('audio')
                  console.log('audio element', audioElement)

                  if (audioElement) {
                    audioElement.muted = false
                    audioElement.play()
                  }
                })
            }
          }
        }
      }
    }

    // } catch (e) {
    //   console.error('AUDIO PLAYER ERROR', e)
    // }
  }

  return (
    <Wrapper>
      <AudioPlayer
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        className={`mobile ${mediaPlayer.isLive ? 'live' : 'player'}`}
        src={mediaPlayer.url}
        customIcons={customIcons}
        onPlay={() => {
          console.log('Play called')
          updteProgressEntries()
        }}
        ref={playerRef}
        muted={false}
        listenInterval={1000}
        onListen={handleListen}
        onPause={handlePause}
        onPlaying={handlePlay}
        onCanPlay={handleCanPlayThrough}
        showJumpControls={!mediaPlayer.isLive}
        showSkipControls={!mediaPlayer.isLive}
        progressJumpSteps={{ forward: 30000, backward: 30000 }}
        customAdditionalControls={[]}
        customProgressBarSection={
          mediaPlayer.isLive ? [RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_TIME] : [RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION]
        }
      // other props here
      />
    </Wrapper>
  )
})
