import { cast, flow, types as t } from 'mobx-state-tree'
import { api, authApi, userApi, subscribeNewsLetter } from '../services/api'
import STORAGE, { StorageKey } from '../services/storage'
import { FlowType, User, WizardParams, WizardPatchParams } from '../types'
import { AuthModalPhase } from './AuthStore'
import { withRootStore } from './withRootStore'

const FavoriteModel = t.model('Favorite', {
  id: t.number,
  mediaId: t.number,
  isSeries: t.boolean
})

const ProgressEntryModel = t.model('ProgressEntry', {
  id: t.number,
  mediaId: t.number,
  position: t.number
})

const UserModel = t.model('User', {
  id: t.number,
  email: t.string,
  firstName: t.maybeNull(t.string),
  lastName: t.maybeNull(t.string),
  wizardCompleted: t.boolean,
  subscribedToNewsLetter: t.boolean,
  favorites: t.array(FavoriteModel),
  progress: t.array(ProgressEntryModel)
})

export const UserStore = t
  .model('UserStore')
  .props({
    fetching: false,
    user: t.maybeNull(UserModel)
  })
  .extend(withRootStore)
  .views((self) => ({
    get isLoggedIn(): boolean {
      return !!self.user
    }
  }))
  .actions((self) => ({
    getUserInfo: flow(function* getUserInfo(): any {
      if (!STORAGE.read({ key: StorageKey.accessToken })) return
      self.fetching = true
      try {
        const res: User | undefined = yield userApi.getUserInfo()
        if (res) {
          self.user = cast(res)
          if (!res.wizardCompleted) {
            self.rootStore.authStore.setPhase(AuthModalPhase.Wizard)
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.fetching = false
      }
    }),

    finishWizard: flow(function* finishWizard(params: WizardParams): FlowType<boolean> {
      self.fetching = true
      const patchParams: WizardPatchParams = {
        ...params,
        wizardComplete: true
      }
      try {
        const res: boolean = yield authApi.finishWizard(patchParams)
        if (res && self.user) {
          const newUser = self.user
          newUser.wizardCompleted = true
          newUser.firstName = params.firstName
          newUser.lastName = params.lastName
          newUser.subscribedToNewsLetter = params.subscribeToNewsLetter
          self.user = newUser

          if (params.subscribeToNewsLetter) {
            yield subscribeNewsLetter(self.user.email)
          }

          self.rootStore.authStore.setPhase(AuthModalPhase.None)
          window.location.href = '/'
        }
        return res
      } catch (e) {
        console.error(e)
        return false
      } finally {
        self.fetching = false
      }
    }),

    clearUserInfo: (): void => {
      self.user = null
    }
  }))
