import ThemesIcon from '../../resources/icons/themes-icon.svg'
import ChannelsIcon from '../../resources/icons/channels-icon.svg'
import PodcastsIcon from '../../resources/icons/podcasts-icon.svg'
import FavoritesIcon from '../../resources/icons/favorite-star-icon.svg'
import { colors } from '../../styles/variables'

export const links = (isMobile: boolean) => {
  const links = [
    { icon: <ThemesIcon fill={colors.deiRed} />, url: '/teemat/', text: 'Teemat' },
    { icon: <ChannelsIcon fill={colors.deiRed} />, url: '/radiokanavat/', text: isMobile ? 'Radio' : 'Radiokanavat' },
    { icon: <PodcastsIcon fill={colors.deiRed} />, url: '/podcastit/', text: 'Podcastit' },
    { icon: <FavoritesIcon fill={colors.deiRed} />, url: '/suosikit/', text: isMobile ? 'Omat' : 'Omat suosikit' },
  ]

  return links
}
