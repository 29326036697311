import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Modal } from '@material-ui/core'

import { LiveChannelPlay } from '../LiveChannelPlay'
import Container from '../Container'
import { useMediaQuery } from 'react-responsive'
import { MenuToggle } from './MenuToggle'
import { Menu } from './Menu'
import { useEffect, useState } from 'react'
import { useMst } from '../../models/Root'
import { observer } from 'mobx-react-lite'
import { links } from './utils'
import { breakpoints } from '../../styles/variables'

import './Navi.css'
import { AuthModal } from '../Auth'

const MenuContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: row !important;
`

const LogoContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`
const MenuRightContainer = styled.div`
  display: flex;
  alignt-items: center;
`

const StyledImage = styled.img`
  height: 80px;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    height: 30px;
    margin-left: 26px;
  }
`

const MainMenuLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const MenuItemLink = styled(Link)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 13.5px;
  line-height: 16px;
  margin-left: 9px;
  align-content: center;
  align-items: center;
  color: white;
  margin-top: 2px;
`

interface NaviProps {}

// @ts-ignore
const Desktop = ({ isDesktop, children }) => {
  return isDesktop ? children : null
}

// @ts-ignore
const Mobile = ({ isDesktop, children }) => {
  return !isDesktop ? children : null
}

const Navi: React.FC<NaviProps> = observer(() => {
  const { menu } = useMst()

  const isDesktop = useMediaQuery({ minWidth: breakpoints.xl })
  const [menuOpen, setMenuOpen] = useState(false)
  const [items, setItems] = useState([])

  useEffect(() => {
    menu.fetchMenu().then((menuData) => {
      // @ts-ignore
      setItems(menuData)
    })
  }, [])

  useEffect(() => {
    console.log('MENU ITEMS CHANGED')
    console.log(menu.menuItems, 'NEW ITEMS')
    menu.menuItems.map((item) => {
      console.log(item, 'MENUITEMI')
    })
    setItems(
      // @ts-ignore
      menu.menuItems.map((item) => {
        return { URL: item.URL, name: item.name }
      })
    )
  }, [menu.menuItems])

  const onMenuToggleClick = () => setMenuOpen(!menuOpen)

  const menuLinks = links(false)

  const imageSrc = '/images/dei_plus_logo.png'

  return (
    <MenuContainer>
      <LogoContainer className="Navi-logo-container">
        <Link to="/">
          <StyledImage src={imageSrc} alt="Dei plus" />
        </Link>
      </LogoContainer>
      <Desktop isDesktop={isDesktop}>
        <MainMenuLinkContainer className={'Navi-link-container'}>
          {menuLinks.map((link) => (
            <MenuItemContainer key={link.text}>
              {link.icon}
              <MenuItemLink to={link.url}>{link.text}</MenuItemLink>
            </MenuItemContainer>
          ))}
        </MainMenuLinkContainer>
        <MenuRightContainer className={'Navi-menu-right-container'}>
          <LiveChannelPlay />
          <MenuToggle onClick={onMenuToggleClick} isOpen={menuOpen} />
        </MenuRightContainer>
      </Desktop>
      <Mobile isDesktop={isDesktop}>
        <LiveChannelPlay />
        <MenuRightContainer className={'Navi-menu-right-container'}>
          <MenuToggle onClick={onMenuToggleClick} isOpen={menuOpen} />
        </MenuRightContainer>
      </Mobile>
      <Modal open={menuOpen} onClose={onMenuToggleClick} aria-labelledby="Menu modal" aria-describedby="This is navigation menu modal">
        <Menu menuOpen={menuOpen} menuItems={items} onMenuToggleClick={onMenuToggleClick} />
      </Modal>
      <AuthModal onOpen={() => setMenuOpen(false)} />
    </MenuContainer>
  )
})

export { Navi }
