import { flow, Instance, types } from 'mobx-state-tree'

import { getSearchResults } from '../services/api'

export const MediaSeriesModel = types.model({
  id: types.number,
  seriesLink: types.boolean,
  showLikeButton: types.boolean,
  showName: types.string,
  title: types.maybeNull(types.string),
  duration: types.maybeNull(types.number),
  mediaSrc: types.maybeNull(types.string),
  mediaSeriesId: types.maybeNull(types.number),
  pictureSrc: types.maybeNull(types.string),
  thumbnailUrl: types.maybeNull(types.string),
  videoType: types.maybeNull(types.string),
  originalUrl: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  videoSeries: types.maybeNull(types.boolean)
})

export type MediaSeriesType = Instance<typeof MediaSeriesModel>

export const SearchStore = types
  .model({
    searchResults: types.array(MediaSeriesModel),
    searchQuery: types.maybeNull(types.string)
  })
  .actions((self) => {
    const doSearch = flow(function* (query: string) {
      console.log('DOSEARCHING')
      self.searchQuery = query
      const results = yield getSearchResults(query)
      console.log(results)
      self.searchResults = results.data
    })

    return {
      doSearch
    }
  })
