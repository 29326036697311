import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { PauseCircleFilled, PlayArrow, PlayCircleFilled } from '@material-ui/icons'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { useMst } from '../../models/Root'
import { RADIO_CHANNEL_MEDIA_ID } from '../Category/utils'
import { colors } from '../../styles/variables'

const PlayButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border-radius: 15px;
`

interface Props {
  mediaId: number
  mediaSrc: string
  showName: string
  title: string
  duration?: number
  progress?: number
  live?: boolean
  marginLeft?: string
  buttonColor?: string
  mediaSeriesId?: number
  backgroundColor?: string
}

const PlayButton: React.FC<Props> = observer((props) => {
  const { mediaPlayer } = useMst()
  const { mediaId, buttonColor = colors.black, backgroundColor = colors.white } = props

  const [playing, setPlaying] = useState(mediaPlayer.isPlaying && mediaPlayer.mediaId === props.mediaId)

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const iconStyle = {
    backgroundColor,
    color: buttonColor,
    marginLeft: props.marginLeft,
    cursor: 'pointer'
  }

  useEffect(() => {
    setPlaying(mediaPlayer.isPlaying && mediaPlayer.mediaId === props.mediaId)
  }, [mediaPlayer.mediaId, mediaPlayer.isPlaying])

  useEffect(() => {
    if (mediaPlayer.paused) {
      setPlaying(false)
    } else if (mediaPlayer.isPlaying && mediaPlayer.mediaId === props.mediaId) {
      setPlaying(true)
    }
  }, [mediaPlayer.paused])

  const playClicked = () => {
    console.log('PLAY FOR MEDIA ID', mediaId, 'CLICKED', mediaPlayer.isPlaying, mediaPlayer.paused)

    if (props.live) {
      console.log('WE ARE LIVE, DOING THINGS')
      if (mediaPlayer.isPlaying && mediaPlayer.mediaId === props.mediaId) {
        if (mediaPlayer.paused) {
          console.log('WE ARE ALREADY PAUSED, SHOULD CONTINUE')
          mediaPlayer.requestResume(true)
          mediaPlayer.setPause(false)
          setPlaying(true)
        } else {
          console.log('PAUSE CALLBACKED FROM PLAYCLICK')
          mediaPlayer.setPause(true)
          setPlaying(false)
        }
      } else {
        console.log('LIVE RADIO BUTTON CLICKED')
        mediaPlayer.switchStream(mediaId - RADIO_CHANNEL_MEDIA_ID)
        setPlaying(true)
        mediaPlayer.setMediaId(props.mediaId)
        mediaPlayer.setPause(false)
      }
      mediaPlayer.setLive(true)
    } else {
      if (mediaPlayer.isPlaying && mediaPlayer.mediaId === props.mediaId) {
        console.log('WE GOT PRESSED THE SAME MEDIA, PAUSE OR RESUME DEPENDING ON STATUS', mediaPlayer.paused)
        if (mediaPlayer.paused) {
          console.log('WE ARE ALREADY PAUSED, SHOULD CONTINUE')
          mediaPlayer.requestResume(true)
          mediaPlayer.setPause(false)
          setPlaying(true)
          mediaPlayer.playThrough();
        } else {
          console.log('PAUSE CALLBACKED FROM PLAYCLICK')
          mediaPlayer.setPause(true)
          setPlaying(false)
        }
      } else {
        console.log('WE GOT PRESSED A NEW MEDIA, LETS PLAY IT')
        mediaPlayer.setStreamURL(props.mediaSrc)
        mediaPlayer.setShowName(props.showName)
        mediaPlayer.setEpisodeName(props.title)
        mediaPlayer.setMediaId(props.mediaId)
        if (props.mediaSeriesId) mediaPlayer.setMediaSeriesId(props.mediaSeriesId)
        if (props.duration) mediaPlayer.setMediaDuration(props.duration)
        if (!mediaPlayer.isPlaying) {
          console.log('MEDIA PLAYER AINT PLAYING, WE GOTS TO START IT')
          mediaPlayer.togglePlay()
          mediaPlayer.setPause(false)
        }
        if (props.progress) {
          console.log('WE HAVE EXISTING PROGRESS, NEED TO INFORM THE PLAYER ABOUT THIS VIA STORE')
          mediaPlayer.setProgress(props.progress)
        }
        console.log('OUR PLAYING STATUS: ', playing)
      }
      mediaPlayer.setLive(false)
    }
  }

  if (!playing)
    return <PlayCircleFilled style={{ fontSize: isMobile ? '30px' : '40px' }} onClick={playClicked} />;
  else
    return <PauseCircleFilled style={{ fontSize: isMobile ? '30px' : '40px' }} onClick={playClicked} />;
})

export { PlayButton }
