import React, { useRef, useEffect } from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
// import 'react-h5-audio-player/lib/styles.css';
import { observer } from 'mobx-react-lite'
import { useMst } from '../../models/Root'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { PlayArrow, Pause } from '@material-ui/icons'

import './mediaplayer.scss'
import { LiveChannelDropdown } from '../LiveChannelDropdown'
import STORAGE, { StorageKey } from '../../services/storage'
import { submitPopularityPulse } from '../../services/api'
import { AdditionalMediaInfo } from '../AdditionalMediaInfo/AdditionalMediaInfo'
import { breakpoints, colors, fonts } from '../../styles/variables'
// @ts-ignore
import SkipBackwardIcon from '../../resources/icons/skip-backward.svg'
// @ts-ignore
import SkipForwardIcon from '../../resources/icons/skip-forward.svg'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100vw;
  height: 100px;
  z-index: 1000;
  background-color: #000;
  display: flex;
  justify-content: space-between;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    background-color: rgba(0, 0, 0, 0.87);
  }
`

const LeftFill = styled.div`
  width: 100px;
  height: 100px;
`

const EpisodeSpan = styled.span`
  font-size: 16px;
`

const MobileControlContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  z-index: 1001;
`

const MobileButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: ${colors.white};
  border-radius: 15px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const MobilePlay = styled(PlayArrow)`
  color: ${colors.red};
`

const MobilePause = styled(Pause)`
  color: ${colors.red};
`

const MobilePlaying = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  justify-content: center;
`

const MobileTitle = styled.span`
  font-size: 14px;
  line-height: 17.07px;
  font-weight: bold;
`

const MobileSubTitle = styled.span`
  font-family: ${fonts.codeProMono};
  font-size: 10px;
  line-height: 11.2px;
  font-weight: 400;
`

const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100px;
  border: 1px solid ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`

const MediaPlayer = observer(() => {
  const {
    mediaPlayer,
    progress: { updateInProgress, removeFromInProgress, updteProgressEntries }
  } = useMst()
  const isMobile = useMediaQuery({ maxWidth: 768 })

  let playerRef = useRef<AudioPlayer>(null)

  useEffect(() => {
    if (playerRef.current) {
      if (mediaPlayer.isPlaying && !playerRef.current?.isPlaying()) {
        //  //@ts-expect-error
        playerRef.current?.audio.current?.play()
      }
    }
  }, [mediaPlayer.isPlaying])

  useEffect(() => {
    if (mediaPlayer.pauseRequested === true) {
      playerRef.current?.audio.current?.pause()
      mediaPlayer.requestPause(false)
    }
  }, [mediaPlayer.pauseRequested])

  useEffect(() => {
    if (mediaPlayer.resumeRequested) {
      playerRef.current?.audio.current?.play()
      mediaPlayer.requestResume(false)
    }
  }, [mediaPlayer.resumeRequested])

  useEffect(() => {
    if (mediaPlayer.skipRequested === true && playerRef.current?.audio.current) {
      playerRef.current.audio.current.currentTime = mediaPlayer.existingProgress
      mediaPlayer.clearProgress()
    }
  }, [mediaPlayer.skipRequested])

  const customIcons = {
    play: (
      <IconContainer>
        <PlayArrow style={{ color: colors.white }} />
      </IconContainer>
    ),
    pause: (
      <IconContainer>
        <Pause style={{ color: colors.white }} />
      </IconContainer>
    ),
    rewind: <SkipBackwardIcon />,
    forward: <SkipForwardIcon />
  }

  const handleListen = (e: Event) => {
    const position = playerRef.current?.audio.current?.currentTime
    const duration = playerRef.current?.audio.current?.duration
    if (e.target !== null && mediaPlayer.isPlaying && !mediaPlayer.isLive && !!position && !!duration) {
      const progress = position / duration
      if (progress >= 0.99) {
        removeFromInProgress(mediaPlayer.mediaId)
      } else {
        updateInProgress(mediaPlayer.mediaId, position)
      }

      if (!mediaPlayer.isLive) {
        let existingPopularityTracking = STORAGE.read({ key: StorageKey.poptrack }) ?? {}

        if (!existingPopularityTracking[mediaPlayer.mediaId]) {
          existingPopularityTracking[mediaPlayer.mediaId] = { t: 1, s: false, tr: Math.floor(mediaPlayer.mediaDuration * 0.1) }
        } else {
          let temp = existingPopularityTracking[mediaPlayer.mediaId]
          temp.t += 1
          if (temp.t >= temp.tr && !temp.s) {
            console.log('WE MUST SEND POPULARITY PULSE TO BACKEND!')
            submitPopularityPulse(mediaPlayer.mediaId)
            temp.s = true
          }
          existingPopularityTracking[mediaPlayer.mediaId] = temp
        }

        STORAGE.write({ key: StorageKey.poptrack, value: existingPopularityTracking })
      }
    }
  }

  const handlePause = (e: Event) => {
    if (e.target !== null && mediaPlayer.isPlaying) {
      console.log('PAUSE CALLBACKED FROM PLAYER')
      mediaPlayer.setPause(true)
      mediaPlayer.setIsPlaying(false)
      updteProgressEntries()
    }
  }

  const handlePlay = (e: Event) => {
    console.log('PLAYING CALLBACK')

    if (e.target !== null) {
      mediaPlayer.setPause(false)
      mediaPlayer.setIsPlaying(true)
    }
  }

  const handleCanPlayThrough = () => {
    if (mediaPlayer.isPlaying) {
      console.log('CAN PLAY THROUGH? START? NORMAL PLAYER?')
      playerRef.current?.audio.current?.play()
    }
  }

  const handleMobileButtonClick = () => {
    mediaPlayer.requestResume(mediaPlayer.paused)
    mediaPlayer.setIsPlaying()
    mediaPlayer.requestPause(mediaPlayer.isPlaying && !mediaPlayer.paused)
    mediaPlayer.setPause(mediaPlayer.isPlaying && !mediaPlayer.paused)
  }

  const getCustomAdditionalControls = () => {
    if (!mediaPlayer.isPlaying) return <></>
    if (mediaPlayer.isLive || mediaPlayer.mediaId === 999999999) return <LiveChannelDropdown desktop={!isMobile} player={true} />
    if (isMobile) {
      return (
        <MobilePlaying style={{ opacity: mediaPlayer.isLive ? 0 : 1 }}>
          <MobileTitle>{mediaPlayer.showName}</MobileTitle>
          <MobileSubTitle>{mediaPlayer.episodeName}</MobileSubTitle>
        </MobilePlaying>
      )
    }
    return (
      <EpisodeSpan style={{ opacity: mediaPlayer.isLive ? 0 : 1 }}>
        {mediaPlayer.showName} / <span className="mp-episode-span">{mediaPlayer.episodeName}</span>
      </EpisodeSpan>
    )
  }

  // console.log(mediaPlayer.isLive)
  // console.log(mediaPlayer.isPlaying)
  // console.log(mediaPlayer.mediaId)

  return (
    <Wrapper>
      {isMobile ? (
        <MobileControlContainer>
          <MobileButton onClick={handleMobileButtonClick}>
            {mediaPlayer.isPlaying && !mediaPlayer.paused ? <MobilePause /> : <MobilePlay />}
          </MobileButton>
          {getCustomAdditionalControls()}
        </MobileControlContainer>
      ) : (
          <>
            <LeftFill />
            <AudioPlayer
              autoPlay={false}
              autoPlayAfterSrcChange={false}
              className={mediaPlayer.isLive ? 'live' : 'player'}
              src={mediaPlayer.url}
              customIcons={customIcons}
              // onPlay={() => mediaPlayer.setIsPlaying(true)}
              onPlay={() => {
                console.log('Play called')
                updteProgressEntries()
              }}
              ref={playerRef}
              listenInterval={1000}
              onListen={handleListen}
              onPause={handlePause}
              onPlaying={handlePlay}
              onCanPlay={handleCanPlayThrough}
              showJumpControls={!isMobile && !mediaPlayer.isLive}
              showSkipControls={!isMobile && !mediaPlayer.isLive}
              progressJumpSteps={{ forward: 30000, backward: 30000 }}
              customProgressBarSection={
                mediaPlayer.isLive
                  ? [RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_TIME]
                  : [RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION]
              }
              customAdditionalControls={[getCustomAdditionalControls()]}
            // other props here
            />
          </>
        )}
      <AdditionalMediaInfo />
    </Wrapper>
  )
})

export { MediaPlayer }
