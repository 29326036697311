import React, { useRef } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

// @ts-ignore
import SearchIcon from '../../resources/icons/search.svg'
import { useMst } from '../../models/Root';
import { colors, breakpoints } from '../../styles/variables'

import './Search.css'

const SearchBackdrop = styled.div`
  border: 1px solid ${colors.black};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-left: 50%;
  transform: translate(-50%, -50%);
`

const SearchDescription = styled.div`
  white-space: nowrap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
`

const Search = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;
`

const SearchInput = styled.input`
  background: ${colors.white};
  border: 1px solid ${colors.white};
  box-sizing: border-box;
  padding-left: 1em !important;
  width: 100%;
`

const StyledSearchIcon = styled(SearchIcon)`
  padding: 0.7rem;
  background: ${colors.white};
  border: 1px solid ${colors.white};
  box-sizing: border-box;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    padding: 0.2rem;
  }
`


// @ts-ignore
interface Props {
  closeMenu: () => void,
}

// @ts-ignore
interface SearchBackdropProps {
  isMobile: Boolean,
}

const SearchBar: React.FC<Props> = (props) => {
  const inputRef = useRef();

  const { search } = useMst();

  const searchClicked = (a: any) => {
    // @ts-expect-error
    console.log('WE SEARCHING FOR: ', inputRef.current?.value);
    // getSearchResults(inputRef.current?.value).then((data) => {
    //   console.log('RESULTS', data.data);
    //   navigate('/hakutulokset', {
    //     state: {
    //       searchResults: data.data
    //     }
    //   })
    // })

    search.doSearch(inputRef.current?.value);
    props.closeMenu();
    navigate('/hakutulokset');
  }

  const keyPress = (e: any) => {
    if (e.keyCode == 13) {
      search.doSearch(e.target.value);
      props.closeMenu();
      navigate('/hakutulokset');
      // put the login here
    }
  }

  return (
    <SearchBackdrop className="Search-container" >
      <SearchDescription className="Search-description">Hae hakusanalla</SearchDescription>
      <Search >
        <SearchInput className="Search-input" ref={inputRef} onKeyDown={keyPress} />
        <StyledSearchIcon className="Search-icon" onClick={searchClicked} />
      </Search>
    </SearchBackdrop>
  )
}

export { SearchBar };
