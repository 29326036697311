import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import MenuOpen from '../../resources/icons/menuopen.svg'
// @ts-ignore
import MenuClosed from '../../resources/icons/menuclosed.svg'
import { rem, transitions } from 'polished'
import { InvisibleButton } from '../Primitives/InvisibleButton'

interface Props {
  isOpen: boolean
  onClick: () => void
}

const Toggle = styled(InvisibleButton)`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem('70px')};
  width: ${rem('70px')};
  transform: ${(props: Props) => props.isOpen && ''};
  ${transitions('transform 0.3s ease-in 0s')}
  &:focus {
    outline: none;
  }
`

const MenuToggle: React.FC<Props> = ({ isOpen, onClick }) => {
  const icon = isOpen ? <MenuOpen style={{ color: 'white' }} /> : <MenuClosed style={{ color: 'white' }} />
  return (
    <Toggle isOpen={isOpen} id="toggle-mobile-menu" onClick={onClick}>
      {icon}
    </Toggle>
  )
}

export { MenuToggle }
