import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { PlayButton } from '../PlayButton'
import { RADIO_CHANNEL_MEDIA_ID } from '../Category/utils'
import { colors } from '../../styles/variables'

require('./LCP.css')

const PlayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const PlayButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #aa1e36;
  color: white;
  border-radius: 100%;
  margin-right: 14px;
  margin-left: -4px;
`

const TextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Source Code Pro', monospace;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
`

interface Props { }

const LiveChannelPlay: React.FC<Props> = observer(() => {
  const defaultStream = {
    mediaId: RADIO_CHANNEL_MEDIA_ID,
    mediaSrc: 'https://stream.dei.fi:8443/yleisohjelma',
    showName: '',
    title: 'Radio Dei - pääkanava'
  }

  return (
    <PlayContainer className={'LCP-container'}>
      <PlayButtonContainer className={'LCP-icon-container'}>
        <PlayButton
          mediaId={defaultStream.mediaId}
          mediaSrc={defaultStream.mediaSrc}
          title={defaultStream.title}
          showName={defaultStream.showName}
          marginLeft="-4px"
          buttonColor={colors.red}
          live={true}
        />
      </PlayButtonContainer>
      <TextContainer className={'LCP-text-container'}>kuuntele Radio Deitä</TextContainer>
    </PlayContainer>
  )
})

export { LiveChannelPlay }
