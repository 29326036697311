import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { CircularProgress, MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps } from '@material-ui/core'
import { colors } from '../../styles/variables'

export const Container = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
})

export const CenteredContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center'
})

export const Title = styled('h2')({
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontSize: 32,
  textAlign: 'center',
  color: colors.white,
  margin: '0 0 2rem 0'
})

export const Text = styled('p')({
  fontFamily: 'Source Code Pro',
  fontWeight: 400,
  fontStyle: 'normal',
  fontSize: 16,
  textAlign: 'left',
  color: colors.white
})

const ButtonElement = styled('button')<{ fetching?: boolean }>((p) => ({
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontSize: 18,
  color: colors.white,
  textAlign: 'center',
  backgroundColor: colors.red,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '15px 30px',
  transition: 'all 0.2s ease-in-out',
  border: 'none',
  width: p.fetching ? 'auto' : '100%',
  margin: p.fetching ? '2rem auto' : '2rem 0',
  borderRadius: p.fetching ? 100 : 0,
  opacity: p.disabled ? 0.5 : 1,
  cursor: p.disabled ? 'not-allowed' : 'pointer'
}))

export const ClearButton = styled('button')({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontSize: 18,
  color: colors.white,
  textAlign: 'center',
  textDecoration: 'underline',
  cursor: 'pointer',
  border: 'none',
  background: 'none',
  margin: '0 0 2rem 0'
})

const InputContainer = styled('div')({
  margin: '25px 0 0 0',
  width: '100%'
})

export const InputLabel = styled('label')({
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontSize: 14,
  alignSelf: 'flex-start',
  textAlign: 'left',
  marginBottom: 5
})

const InputComponent = styled('input')<{ error?: boolean }>(({ error }) => ({
  fontFamily: 'Montserrat',
  fontSize: 14,
  width: '100%',
  padding: '14px 22px',
  backgroundColor: colors.white,
  border: `2px solid ${error ? colors.warning : colors.white}`
}))

const SelectInput = styled(MuiSelect)({
  width: '100%',
  backgroundColor: colors.white,
  border: `2px solid ${colors.white}`,
  marginBottom: 15,
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontSize: 14
  },
  '& .MuiSelect-root': {
    padding: '14px 22px'
  }
})

export const ErrorMessage = styled(Text)({
  color: colors.warning,
  minHeight: 15,
  margin: 0,
  paddingTop: 5
})

export const Required = styled('span')({
  color: colors.warning,
  fontSize: 20
})

type InputProps = {
  type: InputHTMLAttributes<HTMLInputElement>['type']
  label: string
  value: string
  onChange: (e: string) => void
  errorMessage?: string
  required?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>

export const Input: FC<InputProps> = ({ type, label, value, onChange, errorMessage, required, ...rest }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)

  return (
    <InputContainer>
      <>
        <InputLabel>{label}</InputLabel>
        {required && <Required>*</Required>}
      </>
      <InputComponent {...rest} type={type} value={value} onChange={handleChange} error={!!errorMessage?.length} />
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </InputContainer>
  )
}

type ButtonProps = {
  fetching?: boolean
} & Omit<InputHTMLAttributes<HTMLButtonElement>, 'type'>

export const Button: FC<ButtonProps> = ({ fetching, disabled, children, ...rest }) => {
  return (
    <ButtonElement disabled={disabled || fetching} fetching={fetching} {...rest}>
      {fetching ? <CircularProgress color="inherit" /> : children}
    </ButtonElement>
  )
}

export type SelectOption = {
  value: string
  label: string
}

type SelectProps = {
  options: SelectOption[]
  value: string
  label: string
  onChange: (e: string) => void
} & Omit<MuiSelectProps, 'onChange'>

export const Select: FC<SelectProps> = ({ options, label, value, onChange, ...rest }) => {
  const handleChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => onChange(e.target.value as string)
  return (
    <InputContainer>
      <InputLabel>{label}</InputLabel>
      <SelectInput {...rest} onChange={handleChange} value={value} disableUnderline>
        <MenuItem aria-label="" value="" />
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </SelectInput>
    </InputContainer>
  )
}
