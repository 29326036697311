import React, { FC } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

import { useMst } from '../../models/Root'
import { AuthModalPhase } from '../../models/AuthStore'
import { ClearButton } from './shared'

const Button = styled(ClearButton)({
  alignItems: 'center',
  margin: '1rem'
})

export const LoginButton: FC = observer(() => {
  const {
    authStore: { setPhase, logout },
    userStore: { isLoggedIn }
  } = useMst()

  const handleClick = () => (isLoggedIn ? logout() : setPhase(AuthModalPhase.Login))

  return <Button onClick={handleClick}>{isLoggedIn ? 'Kirjaudu ulos' : 'Kirjaudu Dei Plussaan'}</Button>
})
