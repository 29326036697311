import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../models/Root'
import { ErrorMessage } from './shared'

export const AuthError: FC = observer(() => {
  const {
    authStore: { authError }
  } = useMst()

  if (!authError) return null

  const getMessage = () => {
    switch (authError.toLowerCase()) {
      case 'invalid credentials':
      case 'user not found':
      case 'unauthorized':
        return 'Kirjautuminen epäonnistui. Tarkista sähköpostiosoite ja salasana.'
      case 'user already exists':
        return 'Käyttäjätunnus on jo käytössä.'
      default:
        return 'Jokin meni pieleen. Yritä myöhemmin uudelleen.'
    }
  }

  return <ErrorMessage>{getMessage()}</ErrorMessage>
})
