import { cast, flow, Instance, types } from 'mobx-state-tree'
import * as api from '../services/api'
import STORAGE, { StorageKey } from '../services/storage'
import { FlowType, Progress, LocalProgress } from '../types'
import { MediaSeriesModel, MediaSeriesType } from './Search'
import { withRootStore } from './withRootStore'

type ProgressLocalStorageObject = { [key: string]: number } // mediaId: position
type LocalProgressInstance = Instance<typeof LocalProgressModel>

const LocalProgressModel = types.model('LocalProgressModel', {
  id: types.number, // mediaId
  position: types.number,
  series: false
})

const ProgressEntryModel = MediaSeriesModel.props({ progress: types.number })

export const ProgressStore = types
  .model({
    state: types.enumeration(['Initial', 'Loading', 'Error', 'Done']),
    localProgress: types.array(LocalProgressModel),
    progressEntries: types.array(ProgressEntryModel)
  })
  .extend(withRootStore)
  .views((self) => ({
    checkIfIdInProgress(id: number): boolean {
      return !!self.localProgress?.find((f) => f.id === id)
    }
  }))
  .actions((self) => {
    const fetchLocalProgress = flow(function* (): FlowType<LocalProgress[] | undefined> {
      let localProgress: LocalProgress[] = []
      if (self.rootStore.userStore.user) {
        localProgress = (yield api.getUserInProgress(self.rootStore.userStore.user.id)).map((f: Progress) => ({
          id: f.mediaId,
          position: f.position
        }))
      } else {
        const localProgressObject: ProgressLocalStorageObject | undefined = STORAGE.read({ key: StorageKey.progress })
        localProgress = Object.entries(localProgressObject ?? {}).map(([id, position]) => ({
          id: Number(id),
          position: Number(position),
          series: false
        }))
      }
      return localProgress
    })

    const initializeProgress = flow(function* (): FlowType<void> {
      const localProgress: LocalProgress[] | undefined = yield fetchLocalProgress()
      self.localProgress = cast(localProgress ?? [])
      if (localProgress?.length) {
        const ids = new Set()
        const filteredProgress = localProgress.filter(
          obj => !ids.has(obj.id) && ids.add(obj.id)
        )
        const res: MediaSeriesType[] = yield api.getMediaData(filteredProgress)
        const resWithProgress = res.map((it) => ({ ...it, progress: localProgress.find((f) => f.id === it.id)?.position ?? 0 }))
        self.progressEntries = cast(resWithProgress)
      }
      self.state = 'Done'
    })

    const updateInProgress = flow(function* (id: number, position: number): FlowType<void> {
      const existingProgress = self.localProgress.find((f) => f.id === id)

      let newProgress: LocalProgressInstance[] = []

      if (existingProgress) {
        // Update existing progress
        newProgress = [...self.localProgress].map((f) => (f.id === id ? { ...f, position } : f))
      } else {
        // Or add new progress
        newProgress = [...self.localProgress, { id, position, series: false }]
      }

      // Add or update to api
      if (self.rootStore.userStore.user) {
        if (existingProgress) {
          yield api.updateInProgress(self.rootStore.userStore.user.id, id, position)
        } else {
          yield api.addToInProgress(self.rootStore.userStore.user.id, id, position)
        }
        // Add to "local" progress
      } else {
        const storageObject: ProgressLocalStorageObject = STORAGE.read({ key: StorageKey.progress }) ?? {}
        storageObject[id.toString()] = position
        STORAGE.write({ key: StorageKey.progress, value: storageObject })
      }

      // Get media info from api and save to state
      self.localProgress = cast(newProgress)
    })

    const removeFromInProgress = flow(function* (id: number): FlowType<void> {
      const existingProgress = self.progressEntries.find((f) => f.id === id)

      if (!existingProgress) return

      const newProgress = [...self.localProgress].filter((f) => f.id !== id)

      if (self.rootStore.userStore.user) {
        yield api.deleteInProgress(self.rootStore.userStore.user.id, id)
      } else {
        const storageObject: ProgressLocalStorageObject = STORAGE.read({ key: StorageKey.progress }) ?? {}
        delete storageObject[id.toString()]
        STORAGE.write({ key: StorageKey.progress, value: storageObject })
      }

      self.localProgress = cast(newProgress)
      self.progressEntries = cast(self.progressEntries.filter((f) => f.id !== id))
    })

    const updteProgressEntries = flow(function* (): FlowType<void> {
      const ids = new Set()
      const filteredProgress = self.localProgress.filter(
        obj => !ids.has(obj.id) && ids.add(obj.id)
      )

      const res: MediaSeriesType[] = yield api.getMediaData(filteredProgress)
      self.progressEntries = cast(res.map((it) => ({ ...it, progress: filteredProgress.find((f) => f.id === it.id)?.position ?? 0 })))
    })

    return {
      initializeProgress,
      updateInProgress,
      removeFromInProgress,
      updteProgressEntries
    }
  })
