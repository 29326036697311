import { flow, types as t } from 'mobx-state-tree'
import { authApi } from '../services/api'
import STORAGE, { StorageKey } from '../services/storage'
import { FlowType, Login, LoginResponse, RegisterRequest, RegisterResponse } from '../types'
import { withRootStore } from './withRootStore'

export enum AuthModalPhase {
  None = 'None',
  Login = 'Login',
  Register = 'Register',
  ForgotPassword = 'ForgotPassword',
  VerifyEmail = 'VerifyEmail',
  Wizard = 'Wizard'
}

export const AuthStore = t
  .model('AuthStore')
  .props({
    fetching: false,
    modalPhase: t.optional(t.enumeration(Object.keys(AuthModalPhase)), AuthModalPhase.None),
    authError: t.maybeNull(t.string)
  })
  .extend(withRootStore)
  .views((self) => ({
    get isModalOpen(): boolean {
      return self.modalPhase !== AuthModalPhase.None
    },
    get preventModalClose(): boolean {
      return self.modalPhase === AuthModalPhase.Wizard || self.modalPhase === AuthModalPhase.VerifyEmail
    },
    get token(): string | null {
      return STORAGE.read({ key: StorageKey.accessToken })
    }
  }))
  .actions((self) => ({
    setPhase: (phase: AuthModalPhase): void => {
      self.modalPhase = phase
      self.authError = null
    },
    setError: (error?: string): void => {
      self.authError = error ?? 'error'
    }
  }))
  .actions((self) => ({
    login: flow(function* login(request: Login): FlowType<void> {
      self.fetching = true
      try {
        const res: LoginResponse | undefined = request.provider ? yield authApi.externalLogin(request) : yield authApi.login(request)
        if (res?.token) {
          STORAGE.write({ key: StorageKey.accessToken, value: res.token })
          yield self.rootStore.userStore.getUserInfo()
          if (res.user.wizardCompleted) {
            self.setPhase(AuthModalPhase.None)
            window.location.href = '/'
          } else {
            self.setPhase(AuthModalPhase.Wizard)
          }
          self.setPhase(AuthModalPhase[res.user.wizardCompleted ? 'None' : 'Wizard'])
        } else {
          self.setError(res?.message)
        }
      } catch (e) {
        console.error(e)
        self.setError()
      } finally {
        self.fetching = false
      }
    }),

    register: flow(function* register(request: RegisterRequest): FlowType<void> {
      self.fetching = true
      try {
        const res: RegisterResponse = yield authApi.register(request)
        if (res.success) {
          self.setPhase(AuthModalPhase.VerifyEmail)
        } else {
          self.setError(res.message)
        }
      } catch (e) {
        console.error(e)
        self.setError()
      } finally {
        self.fetching = false
      }
    }),

    requestPasswordReset: flow(function* requestPasswordReset(email: string): FlowType<boolean> {
      self.fetching = true
      try {
        return yield authApi.requestPasswordReset(email)
      } catch (e) {
        return false
      } finally {
        self.fetching = false
      }
    }),

    updatePassword: flow(function* updatePassword(token: string, newPassword: string): FlowType<boolean> {
      self.fetching = true
      try {
        return yield authApi.updatePassword(token, newPassword)
      } catch (e) {
        return false
      } finally {
        self.fetching = false
      }
    }),

    verifyEmail: flow(function* verifyEmail(token: string): FlowType<boolean> {
      self.fetching = true
      try {
        return yield authApi.verifyEmail(token)
      } catch (e) {
        console.error(e)
        return false
      } finally {
        self.fetching = false
      }
    }),

    logout: (): void => {
      STORAGE.write({ key: StorageKey.accessToken, value: null })
      self.rootStore.userStore.clearUserInfo()
      window.location.href = '/'
    }
  }))
