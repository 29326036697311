import * as React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { Link } from 'gatsby'

import { heights, dimensions, colors } from '../styles/variables'
import Container from './Container'
import { LiveChannelDropdown } from './LiveChannelDropdown'
import { Navi } from './Navi'
import { MobileNaviLinks } from './Navi/MobileNaviLinks'
import { useMediaQuery } from 'react-responsive'
import { MobileSmartBanner } from './MobileSmartBanner'

const StyledHeader = styled.header`
  height: ${heights.header}px;
  padding-right: ${dimensions.headerPadding}rem;
  padding-left: ${dimensions.headerPadding}rem;
  background-color: ${colors.header};
  color: ${transparentize(0.5, colors.white)};
`

// height: ${heights.mobileHeader}px;
const MobileStyledHeader = styled.header`

  background-color: ${colors.header};
  color: ${transparentize(0.5, colors.white)};
`

const HeaderInnerDesktop = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`

const HeaderInnerMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

const Border = styled.div`
  width: 100%;
  border-top: 1px solid #ffffff;
`

const LogoContainer = styled.div`
  margin-right: 15px;
`

const MenuItemLink = styled(Link)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-right: 50px;
`

const LogoLink = styled(Link)`
  color: ${colors.white};
  font-family: Noteworthy;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 45px;
  letter-spacing: 0.4em;

  color: #000000;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const MainMenuLinkContainer = styled.div`
  float: right;
  margin-left: auto;
`

interface HeaderProps {
  title: string
}

// @ts-ignore
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1280 })
  return isDesktop ? children : null
}

// @ts-ignore
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1279 })
  return isMobile ? children : null
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <div>
      <Mobile>
        <MobileStyledHeader>
          <HeaderInnerMobile>
            <Navi />
            <Border />
            <MobileNaviLinks />
            <Border />
            <MobileSmartBanner />
          </HeaderInnerMobile>
        </MobileStyledHeader>
      </Mobile>
      <Desktop>
        <StyledHeader>
          <HeaderInnerDesktop>
            <Navi />
          </HeaderInnerDesktop>
        </StyledHeader>
      </Desktop>
    </div>
  )
}

export default Header
