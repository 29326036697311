import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { Link } from 'gatsby'
import { CallMade } from '@material-ui/icons'
import NewsLetter from './NewsLetter';
import { colors } from '../styles/variables'
import Container from './Container'
import { useMediaQuery } from 'react-responsive'
import { getFooterLinks } from '../services/api'
import { Menu } from '../types/Menu'

const StyledHr = styled.hr`
  border-top: 2px solid #aa1e36;
  margin: 0;
`

const StyledFooter = styled.div`
  background-color: ${colors.brand};
  color: ${transparentize(0.5, colors.white)};
`

const SocialMediaContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${colors.black};
  height: 140px;
  flex-direction: row !important;
`

const SocialMediaIcon = styled.img`
  width: 50px;
  margin: 1em;
  line-height: 0;
  font-size: unset;
`

const FooterLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${(props: FooterLinkContainerProps) => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  background-color: ${colors.black};
  padding: 1.5em;

  @media ${`(max-width: 900px)`} {
    padding-top: 0;
  }
`

const MenuItemLink = styled(Link)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding: 1em;
  display: flex;
  align-items: center;
  color: ${colors.white};
`

const ExternalMenuItemLink = styled.a`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding: 1em;
  display: flex;
  align-items: center;
  color: ${colors.white};
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em;
`

const StyledImage = styled.img`
  height: 120px;
  width: auto;
`

const CookieBotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.black};
  padding-top: 1.5em;
`

interface FooterProps { }

interface FooterLinkContainerProps {
  isMobile: Boolean
}

const Footer: React.FC<FooterProps> = () => {
  const [footerLinks, setFooterLinks] = useState<Menu[]>([])
  const isMobile = useMediaQuery({ maxWidth: 900 })

  useEffect(() => {
    getFooterLinks().then((res) => {
      setFooterLinks(res.data)
    })
  }, [])

  return (
    <div>
      <StyledFooter>
        <StyledHr />
        <SocialMediaContainer>
          <a href="https://twitter.com/Radio_Dei" target="_blank">
            <SocialMediaIcon src="/images/twitter.png" alt="Twitter" />
          </a>
          <a href="https://www.facebook.com/RadioDei" target="_blank">
            <SocialMediaIcon src="/images/facebook.png" alt="Facebook" />
          </a>
          <a href="https://open.spotify.com/playlist/40KKjRGE8O3Tdxa6A11bLp" target="_blank">
            <SocialMediaIcon src="/images/spotify.png" alt="Spotify" />
          </a>
          <a href="https://www.youtube.com/channel/UCFE09pgjb5SlA472FP_ikpw" target="_blank">
            <SocialMediaIcon src="/images/youtube.png" alt="Youtube" />
          </a>
          <br />
          {!isMobile && <a href="javascript:Cookiebot.renew()">Evästeasetukset</a>}
        </SocialMediaContainer>
        {isMobile && (
          <CookieBotContainer>
            <ExternalMenuItemLink href="javascript:Cookiebot.renew()">
              Evästeasetukset
            </ExternalMenuItemLink>
          </CookieBotContainer>
        )}
        <FooterLinkContainer isMobile={isMobile}>
          {footerLinks.map((link) => {
            const isInsideLink = link.URL.charAt(0) === '/' || link.URL.includes('deiplus')
            return (
              <div key={link.id}>
                {isInsideLink ? (
                  <MenuItemLink to={link.URL}>{link.name}</MenuItemLink>
                ) : (
                    <ExternalMenuItemLink href={link.URL} target="_blank">
                      {link.name}
                      <CallMade />
                    </ExternalMenuItemLink>
                  )}
              </div>
            )
          })}
        </FooterLinkContainer>
        <NewsLetter />
        <ImageContainer>
          <StyledImage src="/images/dei_plus_logo_red_slogan.png" alt="Dei plus" />
        </ImageContainer>
      </StyledFooter>
    </div>
  )
}

export default Footer
