import React, { FC } from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

import FavoriteButton from '../FavoriteButton'
import SomeButtons from '../SomeButtons/SomeButtons'
import { MediaFile } from '../../types'
import { breakpoints, colors, fonts } from '../../styles/variables'

const CloseButton = styled.button`
  position: absolute;
  top: 35px;
  right: 40px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    display: none;
  }
`

const CloseText = styled.span`
  color: ${colors.white};
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-left: 10px;
`

const CloseX = styled(CloseText)`
  font-size: 22px;
  line-height: 27px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  transition: all 250ms;
  overflow-y: scroll;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    width: 90%;
  }
`

const TopContainer = styled.div`
  display: flex;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    flex-direction: column-reverse;
  }
`

const TopRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    justify-content: center;
  }
`

const Title = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`

const Name = styled.span`
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
`

const Image = styled.img`
  width 167px;
  height: 167px;
  margin-right: 35px;
`

const Description = styled.span`
  font-family: ${fonts.codeProMono};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 35px;
`

type Props = {
  visible: boolean
  onAdditionalInfoClose: () => void
  fetching: boolean
  mediaId?: number
  additionalInfo: MediaFile | null,
  isLive: boolean,
}

export const DesktopContent: FC<Props> = ({ fetching, mediaId, additionalInfo, visible, isLive, onAdditionalInfoClose }) => {
  if (fetching) return <CircularProgress />
  if (!additionalInfo) {
    // Display error message if something is supposed to be playing but no info was received
    if (mediaId && !isLive) return <span>Jokin meni pieleen ohjelmatietoja haettaessa :(</span>
    return <></>
  }
  const { title, description, mediaSeries, imageSrc } = additionalInfo
  return (
    <Content style={{ opacity: visible ? 1 : 0 }}>
      <CloseButton onClick={onAdditionalInfoClose}>
        <CloseX>X</CloseX>
        <CloseText>sulje</CloseText>
      </CloseButton>
      <TopContainer>
        {imageSrc && <Image src={imageSrc} />}
        <TopRightContainer>
          <TitleContainer>
            {mediaSeries && <Title>{mediaSeries.name}</Title>}
            {title && <Name>{title}</Name>}
          </TitleContainer>
          {mediaId && <FavoriteButton id={mediaId} series={false} inverse />}
        </TopRightContainer>
      </TopContainer>
      {description && <Description>{decodeURIComponent(description)}</Description>}
    </Content>
  )
}
