/**
 * A simple email validator to the form of something@something.something
 * @param {string} email The email string to be validated
 */
export const isEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/
  return re.test(String(email).toLowerCase())
}

/**
 * We want our passwords to simply be 6 characters or longer
 * @param {string} password The password to be validated
 */
export const isValidPassword = (password: string) => {
  const re = /^(?=.{6,})/
  return re.test(String(password).toLowerCase())
}

/**
 * Checks if the given string looks like a valid name
 * @param {string} name The name to be validated
 */
export const isValidName = (name: string) => {
  const re = /^[A-Za-z\u00C0-\u00FF][A-Za-z\u00C0-\u00FF'-.]+([ A-Za-z\u00C0-\u00FF][A-Za-z\u00C0-\u00FF'-.]+)*$/
  return re.test(String(name).toLowerCase())
}
