import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import styled from 'styled-components'
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'
import AppleSignin from 'react-apple-signin-auth'

import { ClearButton } from './shared'

// @ts-ignore
import Google from '../../resources/icons/Google.svg'
// @ts-ignore
import Apple from '../../resources/icons/Apple.svg'
import { useMst } from '../../models/Root'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const GoogleLogo = styled(Google)({
  width: 26,
  height: 26,
  marginRight: 10
})

const AppleLogo = styled(Apple)({
  width: 30,
  height: 30,
  marginLeft: -3,
  marginRight: 10
})

const Button = styled(ClearButton)({
  justifyContent: 'flex-start',
  margin: '0.5rem 0'
})

export const ExternalSignIn: FC = observer(() => {
  const {
    authStore: { login, setError }
  } = useMst()

  const handleGoogleSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    console.log(res)
    // Handle failure
    if ('code' in res) {
      setError()
      return
    }
    // Login
    login({ accessToken: res.tokenId, provider: 'google' })
  }

  const handleAppleSuccess = (res: any) => console.log(res)

  // Response not typed in libraries
  const handleFailure = (res: any) => console.error(res)

  return (
    <Container>
      <GoogleLogin
        clientId={'297134868558-l8vhts66j3goftsgdkv65dbpk1nstk5e.apps.googleusercontent.com'}
        render={(renderProps) => (
          <Button {...renderProps}>
            <GoogleLogo />
            Kirjaudu Google-tunnuksella
          </Button>
        )}
        buttonText="Login"
        onSuccess={handleGoogleSuccess}
        onFailure={handleFailure}
        cookiePolicy={'single_host_origin'}
      />
    </Container>
  )
})
