import React, { ReactNode } from 'react';
import styled from 'styled-components';

const colors = {
  black: '#222222',
  blackestBlack: '#000',
  cyan: '#00CBCD',
  darkGrey: '#303C4B',
  errorRed: '#d72700',
  rose: '#FFC3D9',
  messageRed: '#9A0037',
  disabledGrey: '#707070',
  placeholderGrey: '#878787',
  greyBorder: '#DADADA',
  grey: '#9C9C9C',
  contentGrey: '#F8F8F8',
  lightBlue: '#F0F9FF',
  lightGrey: '#F6F6F6',
  mainBlue: '#0070BA',
  statusGreen: '#09DB76',
  statusGrey: '#A5A5A5',
  statusOrange: '#FF881A',
  statusPurple: '#C734B8',
  textBlue: '#07234B',
  focusBlue: '#0D3774',
  white: '#ffffff',
  toastGreen: '#4BB543',
  toastRed: '#da2b2b',
  lightGreen: '#EFFFF9',
  teal: '#00CBCD',
  statusYellow: '#F9FC67',
  lightOrange: '#FFB067',
};

type Color = keyof typeof colors;

interface Props {
  color?: Color;
  type?: string;
  onClick: () => void;
  id: string;
  children: ReactNode;
}

const StyledButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  font-size: 1em;
`;

const InvisibleButton: React.FC<Props> = ({ type, ...rest }) => (
  // @ts-ignore
  <StyledButton type={type || 'button'} {...rest} />
);

export { InvisibleButton };
