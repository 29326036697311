
import { flow, types } from "mobx-state-tree";
import { getLatestMediaSeries } from "../services/api";
import { IMediaSeriesLatest } from '../types'

const APIResultModel = types.model({
  seriesLink: types.boolean,
  duration: types.maybe(types.number),
  showLikeButton: types.boolean,
  showName: types.string,
  title: types.maybe(types.string),
  mediaSrc: types.maybe(types.string)
})

export const CategoryStore = types
  .model({
    state: types.enumeration(['Initial', 'Loading', 'Error', 'Done'])
  })
  .actions(self => {

    const getLatestNew = flow(function* () {

      console.log('GET LATEST CALLED');

      try {
        const response = yield getLatestMediaSeries();
        // self.latest = repsonse.data;
        // for (let item of response.data) {
        //   self.latest.push(item);
        // }
        return response.data;
      } catch (error) {
        console.error(error);
        self.state = 'Error'
      }

    })

    return {
      getLatestNew
    }
  });
