import { AxiosError } from 'axios'
import { rootStore } from '../../models/Root'
// import ToastStore from '../stores/ToastStore';

const apiErrorHandler = (error: AxiosError) => {
  let msg: string | null = error.message
  if (error.response) {
    const {
      response: { status }
    } = error

    switch (status) {
      case 401:
        msg = null
        rootStore.authStore.logout()
        break
      case 400:
        break
      case 500:
      case 404:
        break
      default:
        break
    }
  }
  // if (msg) ToastStore.showError(msg);
  throw error
}

export default apiErrorHandler
