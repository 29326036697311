import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { links } from './utils'

const MobileLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 52px;
  width: 100%;
  padding: 1rem;
  margin-right: 0px;
`

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`

const MenuItemLink = styled(Link)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  margin-left: 5px;
  align-content: center;
  align-items: center;
  color: white;
  margin-top: 2px;
`

interface Props {}

const MobileNaviLinks: React.FC<Props> = observer(() => {
  const menuLinks = links(true)

  return (
    <MobileLinksContainer>
      {menuLinks.map((link) => (
        <MenuItemContainer key={link.text}>
          {link.icon}
          <MenuItemLink to={link.url}>{link.text}</MenuItemLink>
        </MenuItemContainer>
      ))}
    </MobileLinksContainer>
  )
})

export { MobileNaviLinks }
