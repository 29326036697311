import React, { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Button, CenteredContainer, ClearButton, Container, Input, Text, Title } from './shared'
import { useMst } from '../../models/Root'
import { AuthModalPhase } from '../../models/AuthStore'
import { isEmail, isValidPassword } from '../../utils/inputValidation'
import { InputError } from '../../types'
import { ExternalSignIn } from './ExternalSignIn'
import { AuthError } from './AuthError'

type Props = {}

export const Register: FC<Props> = observer(() => {
  const {
    authStore: { setPhase, register, fetching }
  } = useMst()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordAgain, setPasswordAgain] = useState<string>('')
  const [errors, setErrors] = useState<InputError[]>([])

  const validateInputs = () => {
    let validationErrors: InputError[] = []

    setEmail(email.trim())
    setPassword(password.trim())
    setPasswordAgain(passwordAgain.trim())

    if (email.trim().length && !isEmail(email.trim())) {
      validationErrors.push({ input: 'email', message: 'Tarkista sähköpostiosoite' })
    }
    if (password.trim().length && !isValidPassword(password.trim())) {
      validationErrors.push({ input: 'password', message: 'Salasanan on oltava väh. 6 merkkiä' })
    }
    if (password.length && passwordAgain.length && password !== passwordAgain) {
      validationErrors.push({ input: 'password', message: 'Salasanat eivät täsmää' })
      validationErrors.push({ input: 'passwordAgain', message: 'Salasanat eivät täsmää' })
    }
    setErrors(validationErrors)
    return validationErrors.length
  }

  const disabled = !email.trim().length || password?.length < 6 || password !== passwordAgain || fetching || !!errors.length

  const handleRegisterClick = () => register({ email: email.trim(), password, passwordAgain })
  const handleLoginClick = () => setPhase(AuthModalPhase.Login)

  return (
    <Container>
      <CenteredContainer>
        <Title>Luo käyttäjätunnus</Title>
      </CenteredContainer>
      <Text>
        Rekisteröityminen kannattaa! Kun kirjaudut Dei Plussaan, näet ohjelmasuosikkisi eri laitteilla ja voit helposti jatkaa kesken
        jäänyttä ohjelmaa. Tunnuksena toimii sähköpostiosoitteesi.
      </Text>
      <Input
        type="email"
        label="Sähköposti"
        value={email}
        onChange={setEmail}
        onBlur={validateInputs}
        errorMessage={errors.find((err) => err.input === 'email')?.message}
      />
      <Input
        type="password"
        label="Salasana"
        value={password}
        onChange={setPassword}
        onBlur={validateInputs}
        errorMessage={errors.find((err) => err.input === 'password')?.message}
      />
      <Input
        type="password"
        label="Salasana uudelleen"
        value={passwordAgain}
        onChange={setPasswordAgain}
        onBlur={validateInputs}
        errorMessage={errors.find((err) => err.input === 'passwordAgain')?.message}
      />
      <AuthError />
      <Button fetching={fetching} onClick={handleRegisterClick} disabled={disabled}>
        Luo tunnus
      </Button>
      <CenteredContainer>
        <Text>Onko sinulla jo Dei Plus -tunnus?</Text>
        <ClearButton onClick={handleLoginClick}>Kirjaudu sisään</ClearButton>
      </CenteredContainer>
      <ExternalSignIn />
    </Container>
  )
})
